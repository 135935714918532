import {useState,useEffect} from 'react'
import { useNavigate,useParams } from 'react-router-dom'
import {Form, Button, Row, Col, Table} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {getUserDetails, updateUserProfile} from '../actions/userActions'
import {listMyOrders} from '../actions/orderActions'
import PaginateOrders from '../components/PaginateOrders'
import { USER_UPDATE_PROFILE_RESET  } from '../constants/userConstants'


const ProfileScreen = () => {
    
    const  {pageNumber =1}  = useParams()


    const [name,setName] = useState('')
    const [email,setEmail] = useState('')
    const [password,setPassword] = useState('')
    const [confirmPassword,setConfirmPassword] = useState('')
    const [message,setMessage] = useState(null)

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const userDetails = useSelector(state=> state.userDetails)
    const {loading, error, user} = userDetails


    const userLogin = useSelector(state=> state.userLogin)
    const {userInfo} = userLogin


    const userUpdateProfile = useSelector(state=> state.userUpdateProfile)
    const {success} = userUpdateProfile


    const orderListMy = useSelector(state=> state.orderListMy)
    const {loading:loadingOrders, error:errorOrders, orders,page,pages} = orderListMy

    useEffect(()=>{
      if(pageNumber){
        dispatch(listMyOrders(pageNumber))
      }

      if(!userInfo){
        navigate('/login')
      }else{
        if (!user || !user.name || success ) {
          setTimeout(()=>{
            dispatch({ type: USER_UPDATE_PROFILE_RESET })
            dispatch(getUserDetails('profile'))
            dispatch(listMyOrders())    
          },1000)
        }else{
            setName(user.name)
            setEmail(user.email)
        }
      }
  
    },[dispatch,navigate,userInfo,user,pageNumber,success])
    

    const submitHandler = (e) => {
        e.preventDefault()
        if(password !== confirmPassword){
            setMessage('Passwords do not match')
        }else{
            dispatch(updateUserProfile({id:user._id, name, email, password}))
        }
    }

  return (
    <Row>
        <Col md={3}>
                <h2>User Profile</h2>
                {message && <Message variant='danger'>{message}</Message>}
                {error && <Message variant='danger'>{error}</Message>}
                {success && <Message variant='success'>Profile Updated</Message>}
                {loading ? ( <Loader />) : error ? ( <Message variant='danger'>{error}</Message>) 
                  : (
                
                  <Form onSubmit={submitHandler} >

                    <Form.Group controlId='name'>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type='name' placeholder='Enter name' value={name} onChange={(e)=>setName(e.target.value)}></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='email'>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control type='email' placeholder='Enter email' value={email} onChange={(e)=>setEmail(e.target.value)}></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='password' className='py-3'>
                    <Form.Label>Password</Form.Label>
                    <Form.Control type='password' placeholder='Enter password' value={password} onChange={(e)=>setPassword(e.target.value)}></Form.Control>
                    </Form.Group>

                    <Form.Group controlId='confirmPassword' className='py-3'>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control type='password' placeholder='Confirm password' value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)}></Form.Control>
                    </Form.Group>

                    <Button type='submit' variant='primary'>
                        Update
                    </Button>

                </Form>
                  )}
        </Col>
        <Col md={9}>
            <h2>My Orders</h2>
            {loadingOrders ? <Loader /> : errorOrders ? <Message variant='danger'>{errorOrders}</Message> :(
              <>
              <Table striped bordered hover responsive className='table-sm'>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>DATE</th>
                      <th>TOTAL</th>
                      <th>PAID</th>
                      <th>DELIVERED</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {orders.map((order)=>(
                        <tr key={order._id}>
                          <td>{order._id}</td>
                          <td>{order.createdAt.substring(0,10)}</td>
                          <td>${order.totalPrice}</td>
                          <td>{order.isPaid ? order.paidAt.substring(0,10) : (
                            <i className='fas fa-times' style={{color:'red'}}></i>
                          )}</td>
                          <td>{order.isDelivered ? order.deliveredAt.substring(0,10) : (
                            <i className='fas fa-times' style={{color:'red'}}></i>
                          )}</td>
                          <td>
                            <LinkContainer to={`/order/${order._id}`}>
                              <Button className='btn-sm' variant='light'>Details</Button>
                            </LinkContainer>
                          </td>
                        </tr>
                      ))}
                  </tbody>
              </Table>
              <PaginateOrders pages={pages} page={page}></PaginateOrders>
              
              </>
              
            )}
        </Col>
    </Row>
  )
}

export default ProfileScreen