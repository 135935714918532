// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBpKjJWfVRES0gFtI5FbypD25R8Pcx2YIM",
  authDomain: "shop-59d9a.firebaseapp.com",
  projectId: "shop-59d9a",
  storageBucket: "shop-59d9a.appspot.com",
  messagingSenderId: "590871173797",
  appId: "1:590871173797:web:1a7f917c92d2283a4f4b4c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export default app;