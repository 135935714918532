import {useState,useEffect} from 'react'
import {Link ,useParams, useNavigate } from 'react-router-dom'
import {Form, Button, } from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import {getUserDetails,updateUser} from '../actions/userActions'
import { USER_UPDATE_RESET } from '../constants/userConstants'

const UserEditScreen = () => {

    const { id:userId } = useParams();

    const [name,setName] = useState('')
    const [email,setEmail] = useState('')
    const [isAdmin,setIsAdmin] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const userDetails = useSelector(state=> state.userDetails)
    const {loading, error, user} = userDetails


    const userUpdate = useSelector(state=> state.userUpdate)
    const {loading: loadingUpdate, error:errorUpdate, success:successUpdate} = userUpdate


    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    useEffect(()=>{
        if (!userInfo) {
            navigate('/login')
            
        }
        if(successUpdate){

            dispatch({type:USER_UPDATE_RESET})
            navigate('/admin/userlist')

        }else{
            if(!user?.name || user._id !== userId){
                dispatch(getUserDetails(userId))
            }else{
                setName(user?.name)
                setEmail(user?.email)
                setIsAdmin(user?.isAdmin)
            }
        }
    },[userInfo,dispatch,navigate,userId,user,successUpdate])
    

    const submitHandler = (e) => {
      e.preventDefault()
      dispatch(updateUser({_id: userId, name, email ,isAdmin}))
    }

  return (
    <>
        <Link className='btn btn-light my-3' to='/admin/userlist'>
            Go Back
        </Link>
            <FormContainer>
                <h1>Edit User</h1>
                {loadingUpdate && <Loader />}
                {errorUpdate && <Message variant='danger'>{errorUpdate}</Message>}
                {loading ?  <Loader/> : error ? <Message variant='danger'>{error}</Message>:(
                    <Form  onSubmit={submitHandler} >

                        <Form.Group  controlId='name'>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type='name' placeholder='Enter name' value={name} onChange={(e)=>setName(e.target.value)}></Form.Control>
                        </Form.Group>

                        <Form.Group className='my-3' controlId='email'>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type='email' placeholder='Enter email' value={email} onChange={(e)=>setEmail(e.target.value)}></Form.Control>
                        </Form.Group>

                        <Form.Group controlId='isadmin'>
                            <Form.Check type='checkbox' label='Is Admin' checked={isAdmin}  onChange={(e)=>setIsAdmin(e.target.checked)}></Form.Check>
                        </Form.Group>

                   
                        

                        <Button className='my-3' type='submit' variant='primary'>
                            Update
                        </Button>

                    </Form>

                )}

        
            </FormContainer>
    </>
   
  )
}

export default UserEditScreen